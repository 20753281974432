import HeaderCom from '../../../components/M-header.vue';
import {bizURL} from "@/config/baseUrl";

export default {
    name: 'audit',
    components: {
        HeaderCom
    },
    data() {
        return {
            total: 0, // 总条目数
            currentPage: 1, // 当前页
            limit: 10, // 每页显示数量
            loading: false,
            // isDisabled: false, // 查看禁用表单
            stateList: [{value: '0', label: '待审核'}, {value: '1', label: '审核通过'}, {value: '2', label: '审核不通过'}],
            formInline: {},
            tabColumns: [
                {title: '序号', type: 'index', width: 50, align: 'center'},
                {title: '姓名', key: 'name', align: 'center', minWidth: 80},
                {title: '性别', key: 'gender', align: 'center', width: 50},
                {title: '身份证号码', key: 'idNumber', align: 'center', tooltip: true, resizable: true, minWidth: 120},
                {title: '联系电话', key: 'tel', align: 'center', tooltip: true, resizable: true, width: 110},
                {title: '最高学历', key: 'highestDegree', align: 'center', width: 110},
                {title: '测试专业名称', key: 'examMajor', align: 'center', tooltip: true, width: 130},
                {title: '测试级别', key: 'examMajorTitle', align: 'center', width: 100},
                {title: '工作单位', key: 'unitName', align: 'center', tooltip: true, resizable: true, width: 120},
                {title: '现任专业职务', key: 'majorJob', align: 'center', tooltip: true, width: 110},
                {title: '现任专业职务级别', key: 'majorJobTitle', align: 'center', tooltip: true, resizable: true, width: 140},
                {title: '提交时间', key: 'submitDate', align: 'center', tooltip: true, resizable: true, width: 100},
                {
                    title: '审核状态', key: 'checked', width: 100, align: 'center',
                    render: (h, params) => {
                        let tmpStr = "";
                        if (params.row.checked == 2) {
                            tmpStr = "审核不通过"; // #ed3f14
                        } else if (params.row.checked == 1) {
                            tmpStr = "审核通过"; // #19be6b
                        } else {
                            tmpStr = "待审核"; // #2d8cf0
                        }
                        return h('span', {
                            style: {
                                color: (params.row.checked == 2) ? "#ed3f14" : (params.row.checked == 1 ? "#19be6b" : "#2d8cf0")
                            }
                        }, tmpStr)
                    }
                },
                {title: '审核时间', key: 'checkDate', align: 'center', tooltip: true, resizable: true, width: 160},
                {title: '操作', slot: 'action', width: 180, align: 'center', fixed: 'right'}
            ],
            tabData: [],
            isBlock: true,
            batchList: [],//批次
            genderList: [], // 性别
            degreeList: [], // 最高学历
            specialList: [], // 测试专业名称
            levelList: [], // 测试专业级别
            jobList: [], // 现任专业职务
            titleList: [], // 现任专业职务级别
            title: '审核',
            addApplyModal: false,
            spinShow: true, // 加载状态
            uploadUrl: bizURL + "/registration/registration/upload",
            uploadData: {Authorization: sessionStorage.getItem("manageToken")},
            defaultList: [],
            imgName: "",
            visible: false,
            uploadList: [],
            fileData: {},
            frontData: [], // 身份证人像面
            backData: [], // 身份证国徽面
            bookData: [], // 职称证书
            registionData: [], // 盖章报名表
            ideaModal: false, // 查看审核意见
            addApplyForm: {
                photo: '',
                name: '',
                gender: '男',
                idNumber: '',
                tel: '',
                highestDegree: '',
                graduationDate: '',
                workingYears: '',
                major: '',
                majorWorkYears: '',
                majorJob: '',
                majorJobTitle: '',
                jobDate: '',
                unitName: '',
                archivesUnit: '',
                postCode: '',
                examMajor: '',
                examMajorTitle: '',
                address: '',
                idcardHead: '', //身份证头像面
                idcardNationalEmblem: '', //身份证国徽面
                registion: '', //盖章报名表
                titleCertificate: '', //职称证书表
            },
            addApplyRule: {
                photo: [{
                    required: true, message: '本人证件照为必填', trigger: 'change',
                    // validator: (rule, value, callback) => {
                    //   if (!value) {
                    //     return callback(new Error('本人证件照为必填'));
                    //   } else {
                    //     callback();
                    //   }
                    // }
                }],
                name: [{required: true, message: '姓名为必填', trigger: 'change'}],
                idNumber: [
                    {required: true, message: '身份证号为必填', trigger: 'change'},
                    {pattern: /(^\d{15}$)|(^\d{17}([0-9]|x|X)$)/, message: "证件号码格式有误！", trigger: "change"}
                ],
                tel: [
                    {required: true, message: '联系电话为必填', trigger: 'change'},
                    {pattern: /^1\d{10}$/, message: "手机号格式不对", trigger: "change"}
                ],
                highestDegree: [{required: true, message: '最高学历为必填', trigger: 'change'}],
                graduationDate: [{required: true, message: '毕业时间为必填', trigger: 'change'}],
                workingYears: [{required: true, message: '工作年限为必填', trigger: 'change'}],
                major: [{required: true, message: '所学专业为必填', trigger: 'change'}],
                majorWorkYears: [{required: true, message: '专业工作年限为必填', trigger: 'change'}],
                majorJob: [{required: true, message: '现任专业职务为必填', trigger: 'change'}],
                majorJobTitle: [{required: true, message: '现任专业职务级别为必填', trigger: 'change'}],
                jobDate: [{required: true, message: '任现职时间为必填', trigger: 'change'}],
                unitName: [{required: true, message: '工作单位为必填', trigger: 'change'}],
                archivesUnit: [{required: true, message: '存档单位为必填', trigger: 'change'}],
                postCode: [{required: true, message: '邮政编码为必填', trigger: 'change'}],
                examMajor: [{required: true, message: '测试专业名称为必填', trigger: 'change'}],
                examMajorTitle: [{required: true, message: '测试级别为必填', trigger: 'change'}],
                address: [{required: true, message: '通讯地址为必填', trigger: 'change'}],
                idcardHead: [{required: true, message: '身份证头像面为必填', trigger: 'change'}],
                idcardNationalEmblem: [{required: true, message: '身份证国徽面为必填', trigger: 'change'}],
                titleCertificate: [{required: true, message: '职称证书表为必填', trigger: 'change'}]
            },
            passModal: false,
            passTitle: '审核通过',
            labelCom: '审核通过意见',
            passForm: {
                result: '通过',
                comment: '审核通过，请缴费!',
                name: '管理员',
                time: '2020-09-09',
                passFlag: true
            },
            passRule: {},
            ideaColumn: [
                {title: '序号', type: 'index', width: 50, align: 'center'},
                {title: '操作人', key: 'createBy', align: 'center'},
                {title: '操作时间', key: 'createTime', align: 'center'},
                {title: '状态', key: 'workFlowName', align: 'center'},
                {title: '审核意见', key: 'comment', align: 'center'}
            ],
            ideaData: [],
            finishTime: {
                shortcuts: [
                    {
                        text: "今天",
                        value() {
                            return new Date();
                        },
                    },
                    {
                        text: "昨天",
                        value() {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            return date;
                        },
                    },
                    {
                        text: "一周前",
                        value() {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            return date;
                        },
                    },
                ]
            },
        }
    },
    mounted() {
        // this.getInfo();
        this.getBatchOptions();
        // this.getData();
        this.biz_gender();
        this.biz_degree();
        this.biz_exam_speciality();
        this.biz_exam_speciality_level();
        this.biz_job();
        this.biz_title();
        this.biz_examSubject();
    },
    methods: {
        //批次list  同步方法必须先执行他
        async getBatchOptions() {
            await this.$manageHttp.getBatchOptions().then(data => {
                if (data.code == '200') {
                    this.batchList = data.data;
                    this.formInline.batchId=this.batchList[0].id;
                     this.getData();
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },

        // 性别
        biz_gender() {
            this.$manageHttp.biz_gender().then(data => {
                if (data.code == '200') {
                    this.genderList = data.data;
                    sessionStorage.setItem('genderList', data.data);
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 学历
        biz_degree() {
            this.$manageHttp.biz_degree().then(data => {
                if (data.code == '200') {
                    this.degreeList = data.data;
                    sessionStorage.setItem('degreeList', JSON.stringify(data.data));
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 测试专业名称
        biz_exam_speciality() {
            this.$manageHttp.biz_exam_speciality().then(data => {
                if (data.code == '200') {
                    this.specialList = data.data;
                    sessionStorage.setItem('specialList', JSON.stringify(data.data));
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 测试专业级别
        biz_exam_speciality_level() {
            this.$manageHttp.biz_exam_speciality_level().then(data => {
                if (data.code == '200') {
                    this.levelList = data.data;
                    sessionStorage.setItem('levelList', JSON.stringify(data.data));
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 现任专业职务
        biz_job() {
            this.$manageHttp.biz_job().then(data => {
                if (data.code == '200') {
                    this.jobList = data.data;
                    sessionStorage.setItem('jobList', JSON.stringify(data.data));
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 现任专业职务级别
        biz_title() {
            this.$manageHttp.biz_title().then(data => {
                if (data.code == '200') {
                    this.titleList = data.data;
                    sessionStorage.setItem('titleList', JSON.stringify(data.data));
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 获取考试科目
        biz_examSubject() {
            this.$manageHttp.biz_examSubject().then(data => {
                if (data.code == '200') {
                    this.subjectList = data.data;
                    sessionStorage.setItem('subjectList', JSON.stringify(data.data));
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 表单清空
        resetForm(fileName) {
            this.$refs[fileName].resetFields();
        },
        visibleChange(val) {
            if (val) {
                this.spinShow = true;
            } else {
                this.resetForm('addApplyForm');
            }
        },
        // 查看报名表
        viewApply(row) {
            this.id = row.id;
            this.addApplyModal = true;
            this.resetForm('addApplyForm');
            this.getRegistrationInfo();
            this.title = '查看报名表';
            this.isBlock = true;
            // this.isDisabled = true;
        },
        onSearch() {
            this.currentPage = 1;
            // this.$refs.page.init();
            this.getData();
        },
        // 页码改变的回调，返回改变后的页码(当前页)
        sizeChange(val) {
            this.currentPage = val;
            this.getData();
        },
        // 切换每页条数时的回调，返回切换后的每页条数(每页数)
        pageChange(val) {
            this.limit = val;
            this.getData();
        },
        getData() {
            this.loading = true;
            var params = {
                pageNum: this.currentPage,
                pageSize: this.limit,
                orderByColumn: 'a.submitDate',
                isAsc: 'asc',
                name: this.formInline.name,
                idNumber: this.formInline.idNumber,
                tel: this.formInline.tel,
                unitName: this.formInline.unitName,
                highestDegree: this.formInline.highestDegree,
                examMajor: this.formInline.examMajor,
                examMajorTitle: this.formInline.examMajorTitle,
                majorJob: this.formInline.majorJob,
                majorJobTitle: this.formInline.majorJobTitle,
                checked: this.formInline.checked,
                batchId: this.formInline.batchId
            }
            this.$manageHttp.auditList(params).then(data => {
                if (data.code == '200') {
                    this.loading = false;
                    this.tabData = data.rows;
                    this.total = data.total;
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {

            })
        },
        // 导出报名表
        exportList() {
            var params = {
                pageNum: 1,
                pageSize: 10000,
                orderByColumn: 'a.submitDate',
                isAsc: 'asc',
                name: this.formInline.name,
                idNumber: this.formInline.idNumber,
                tel: this.formInline.tel,
                unitName: this.formInline.unitName,
                highestDegree: this.formInline.highestDegree,
                examMajor: this.formInline.examMajor,
                examMajorTitle: this.formInline.examMajorTitle,
                majorJob: this.formInline.majorJob,
                majorJobTitle: this.formInline.majorJobTitle,
                checked: this.formInline.checked,
                batchId: this.formInline.batchId
            };
            this.$manageHttp.export(params).then(data => {
                if (data.code == '200') {
                    this.$manageHttp.download(data.msg);
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        finishChange(val) {
            this.addApplyForm.graduationDate = val;
        },
        jobDateChange(val) {
            this.addApplyForm.jobDate = val;
        },
        beforeUpload(file) {
            const isLt1M = file.size / 1024 / 1024 < 1;
            if (!isLt1M) {
                this.$Message.warning({
                    background: true,
                    content: "上传图片大小不能超过 1MB！"
                });
            }
            return isLt1M;
        },
        // 身份证人像面
        frontSuccess(res, file) {
            console.log(file, 'file');
            this.frontData = file;
            file.url = res.url;
            file.name = res.fileName;
            this.addApplyForm.idcardHead = res.url;
            // this.$refs.photo.clearFiles(); // 清空头像
        },
        // 身份证国徽面
        backSuccess(res, file) {
            this.backData = file;
            file.url = res.url;
            file.name = res.fileName;
            this.addApplyForm.idcardNationalEmblem = res.url;
            // this.$refs.photo.clearFiles(); // 清空头像
        },
        // 职称证书
        bookSuccess(res, file) {
            this.bookData = file;
            file.url = res.url;
            file.name = res.fileName;
            this.addApplyForm.titleCertificate = res.url;
            // this.$refs.photo.clearFiles(); // 清空头像
        },
        // 盖章报名表
        registionSuccess(res, file) {
            this.registionData = file;
            file.url = res.url;
            file.name = res.fileName;
            this.addApplyForm.registion = res.url;
            // this.$refs.photo.clearFiles(); // 清空头像
        },
        handleView(name) {
            this.imgName = name;
            this.visible = true;
        },
        handleRemove(file) {
            const fileList = this.$refs.upload.fileList;
            this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
        },
        handleSuccess(res, file) {
            file.url = 'https://o5wwk8baw.qnssl.com/7eb99afb9d5f317c912f08b5212fd69a/avatar';
            file.name = '7eb99afb9d5f317c912f08b5212fd69a';
        },
        // 审核
        auditBtn(row) {
            this.id = row.id;
            this.addApplyModal = true;
            this.title = '审核';
            this.isBlock = false;
            this.getRegistrationInfo();
        },
        // 获取单条报名记录
        getRegistrationInfo() {
            // this.spinShow = true;
            this.$manageHttp.getRegistrationInfo({
                id: this.id
            }).then(data => {
                if (data.code == '200') {
                    var data = data.data;
                    this.addApplyForm.name = data.name;
                    this.addApplyForm.gender = data.gender;
                    this.addApplyForm.idNumber = data.idNumber;
                    this.addApplyForm.tel = data.tel;
                    this.addApplyForm.highestDegree = data.highestDegree;
                    this.addApplyForm.graduationDate = data.graduationDate;
                    this.addApplyForm.workingYears = data.workingYears;
                    this.addApplyForm.major = data.major;
                    this.addApplyForm.majorWorkYears = data.majorWorkYears;
                    this.addApplyForm.majorJob = data.majorJob;
                    this.addApplyForm.majorJobTitle = data.majorJobTitle;
                    this.addApplyForm.jobDate = data.jobDate;
                    this.addApplyForm.unitName = data.unitName;
                    this.addApplyForm.archivesUnit = data.archivesUnit;
                    this.addApplyForm.postCode = data.postCode;
                    this.addApplyForm.examMajor = data.examMajor;
                    this.addApplyForm.examMajorTitle = data.examMajorTitle;
                    this.addApplyForm.address = data.address;
                    this.addApplyForm.photo = data.photo; //登记照
                    this.fileData.url = data.photo; //登记照
                    this.addApplyForm.idcardHead = data.idcardHead; //身份证头像面
                    this.frontData = [];
                    this.frontData.push({url: data.idcardHead});
                    this.addApplyForm.idcardNationalEmblem = data.idcardNationalEmblem; //身份证国徽面
                    this.backData = [];
                    this.backData.push({url: data.idcardNationalEmblem});
                    this.addApplyForm.titleCertificate = data.titleCertificate; //职称证书表
                    this.bookData = [];
                    this.bookData.push({url: data.titleCertificate});
                    this.addApplyForm.registion = data.registion; //盖章报名表
                    this.registionData = [];
                    this.registionData.push({url: data.registion});
                    this.spinShow = false;
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {

            })
        },
        // 审核撤回
        recall(row) {
            var idList = [];
            idList.push(row.id);
            var params = {
                regIdList: idList
            }
            this.$manageHttp.auditSubmit(params).then(data => {
                if (data.code == '200') {
                    this.addApplyModal = false;
                    this.getData();
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 审核通过
        passBtn() {
            this.passModal = true;
            this.passTitle = '审核通过';
            this.passForm.result = '通过';
            this.labelCom = '审核通过意见';
            this.passForm.comment = '审核通过，请缴费!';
            this.passForm.passFlag = true;
        },
        // 审核不通过
        nopassBtn() {
            this.passModal = true;
            this.passTitle = '审核不通过';
            this.passForm.result = '不通过';
            this.labelCom = '审核不通过意见';
            this.passForm.comment = '审核不通过，请补充资料!';
            this.passForm.passFlag = false;
        },
        // 审核通过提交
        passSure() {
            var regIdList = [];
            regIdList.push(this.id);
            this.$manageHttp.check({
                regIdList: regIdList,
                comment: this.passForm.comment,
                passFlag: this.passForm.passFlag,
            }).then(data => {
                if (data.code == '200') {
                    this.passModal = false;
                    this.addApplyModal = false;
                    this.getData();
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {

            })
        },
        // 查看审核意见
        viewIdea() {
            this.ideaModal = true;
            this.commentList();
        },
        // 查看审核意见
        commentList() {
            this.$manageHttp.commentList({
                regId: this.id
            }).then(data => {
                if (data.code == '200') {
                    this.ideaData = data.rows;
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        }
    }
}